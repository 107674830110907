import { PusherActionType, PusherReduxAction } from '../../../pusher/PusherActions';
import { put, select } from 'redux-saga/effects';
import { getTrainingItemsOverviewState } from '../../tracked-items-overview/selectors';
import { setState } from '../../tracked-items-overview/actions';
import { Pilot } from '../../../../common/types/pilot';

export function* trackedItemCreated(
  action: PusherReduxAction<PusherActionType.TRACKED_ITEM_CREATED>,
) {
  const { ID, UserID, DueAtDate } = action.payload;

  const { users } = yield select(getTrainingItemsOverviewState);

  const updatedUsers = users.map((user: Pilot) => {
    if (user.ID === UserID) {
      let updatedTrackedItems = [];
      if (Array.isArray(user?.TrackedItems)) {
        updatedTrackedItems = [...user.TrackedItems, { ID, DueAtDate }];
      } else {
        updatedTrackedItems = [{ ID, DueAtDate }];
      }

      updatedTrackedItems.sort(
        (a, b) => new Date(a.DueAtDate).getTime() - new Date(b.DueAtDate).getTime(),
      );

      return { ...user, TrackedItems: updatedTrackedItems };
    }
    return user;
  });

  yield put(setState({ users: updatedUsers }));
}
