import MODAL_MODE from '../../../pages/data/common/constants';
import { ModalMode } from '../crew-scheduling-v3/modals/interfaces';
import actions from './actions';

export interface TrainingItemModalInitialState {
  modalMode: ModalMode;
  isFilterDrawerVisible: boolean;
  isFilterDrawerIconVisible: boolean;
  filterCertificateName: string;
  filterAircraftTypes: string[];
  filterUserID: string;
  filterSelectedStatus: string;
  filterDueDate: string[];
  filterCompletionDate: string[];
  filterCreationDate: string[];
  filterEffectiveDate: string[];
  filterQualificationName: string;
}

const initialState: TrainingItemModalInitialState = {
  modalMode: { open: false, mode: MODAL_MODE.create },
  isFilterDrawerVisible: false,
  isFilterDrawerIconVisible: false,
  filterCertificateName: null,
  filterUserID: null,
  filterSelectedStatus: null,
  filterDueDate: null,
  filterCompletionDate: null,
  filterCreationDate: null,
  filterEffectiveDate: null,
  filterQualificationName: null,
  filterAircraftTypes: null,
};

export default function reducers(state: TrainingItemModalInitialState = initialState, action) {
  switch (action.type) {
    case actions.OPEN_MODAL:
      return { ...state, modalMode: { open: true, mode: action.payload.mode } };
    case actions.CLOSE_MODAL:
      return { ...state, modalMode: { open: false, mode: MODAL_MODE.create } };
    case actions.SET_STATE:
    case actions.SET_IS_FILTER_DRAWER_VISIBLE:
    case actions.SET_IS_FILTER_DRAWER_ICON_VISIBLE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
