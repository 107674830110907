import MODAL_MODE from '../../../pages/data/common/constants';
import { Qualification, QualificationUserRelation } from '../../../pages/data/organization/tracked-items/types';

const actions = {
  OPEN_MODAL: 'pages/qualifications/OPEN_MODAL',
  CLOSE_MODAL: 'pages/qualifications/CLOSE_MODAL',
  SET_QUALIFICATIONS: 'pages/qualifications/SET_QUALIFICATIONS',
  SET_QUALIFICATION_USER_RELATIONS: 'pages/qualifications/SET_QUALIFICATION_USER_RELATIONS',
  SET_FILTER_NAME: 'pages/qualifications/SET_FILTER_NAME',
};

export interface OpenQualificationModalAction {
  type: typeof actions.OPEN_MODAL;
  payload: { mode: MODAL_MODE };
}

export interface CloseQualificationModalAction {
  type: typeof actions.CLOSE_MODAL;
}

export interface SetQualificationsAction {
  type: typeof actions.SET_QUALIFICATIONS;
  payload: { qualifications: Qualification[] };
}

export interface SetQualificationUserRelationsAction {
  type: typeof actions.SET_QUALIFICATION_USER_RELATIONS;
  payload: { qualificationUserRelations: QualificationUserRelation[] };
}

export interface SetFilterNameAction {
  type: typeof actions.SET_FILTER_NAME;
  payload: { filterName: string };
}


export const openQualificationModalAction = (mode: MODAL_MODE): OpenQualificationModalAction => ({
  type: actions.OPEN_MODAL,
  payload: { mode },
});

export const closeQualificationModalAction = (): CloseQualificationModalAction => ({
  type: actions.CLOSE_MODAL,
});

export const setQualificationsAction = (
  qualifications: Qualification[],
): SetQualificationsAction => ({
  type: actions.SET_QUALIFICATIONS,
  payload: { qualifications },
});

export const setQualificationUserRelationAction = (
  qualificationUserRelations: QualificationUserRelation[],
): SetQualificationUserRelationsAction => ({
  type: actions.SET_QUALIFICATION_USER_RELATIONS,
  payload: { qualificationUserRelations },
});

export const setFilterNameAction = (
  filterName: string
): SetFilterNameAction => ({
  type: actions.SET_FILTER_NAME,
  payload: { filterName },
});

export default actions;
