import { call, put } from 'redux-saga/effects';
import api from '../../../../../../../services/api';
import {
  selectCompanyIDSaga,
  selectCompanySettingsSaga,
  selectUserRoleSaga,
  selectUserSaga,
} from '../../../../../../../common/saga/selectors';
import { scheduleActions } from '../../actions';
import { AircraftTimelineEntry } from '../../../../../../../common/types/timeline/AircraftTimelineEntry';
import { AircraftTimelineEntryType } from '../../../../../../../common/types/timeline/AircraftTimelineEntryType';
import { CrewTimelineEntryType } from '../../../../../../../common/types/timeline/CrewTimelineEntryType';
import { entryTypeInStateMap } from '../../../types/state';
import {
  selectAircraftScheduleTimelineEntriesSaga,
  selectAllEntriesSagas,
  selectMXAircraftScheduleTLEntriesSaga,
} from '../../selectors-saga';
import _ from 'lodash';
import { Issue } from '../../../../../../../common/types/CombinedEntry';
import { CrewScheduleTimelineEntry } from '../../../../../../../common/types/timeline/CrewScheduleTimelineEntry';
import {
  selectCompanyScheduleVersionSaga,
  selectPreferredVersionSaga,
  selectPublishedVersionsSaga,
} from '../../../components/Versioning/redux-saga/selectors-saga';
import moment from 'moment-timezone';
import { ContractTimelineEntryType } from '../../../../../../../common/types/timeline/ContractTimelineEntryType';
import { MaintenanceTimelineEntryType } from '../../../../../../../common/types/timeline/MaintenanceTimelineEntryType';
import {
  MaintenanceAircraftTimelineEntry
} from '../../../../../../../common/types/timeline/MaintenanceAircraftTimelineEntry';

export function* fetchTimelineEntries(
  from: string,
  to: string,
  entryType:
    | CrewTimelineEntryType
    | AircraftTimelineEntryType
    | ContractTimelineEntryType
    | MaintenanceTimelineEntryType,
  isExtension = false,
) {
  const userRoles = yield* selectUserRoleSaga();
  const companyID = yield* selectCompanyIDSaga();
  const companySettings = yield* selectCompanySettingsSaga();
  const publishedVersions = yield* selectPublishedVersionsSaga();
  const preferredVersion = yield* selectPreferredVersionSaga();
  const companyScheduleVersion = yield* selectCompanyScheduleVersionSaga();

  const baseApiParams = {
    end_time__gt: from,
    start_time__lt: to,
    limit: -1,
    user_ids: [],
  };

  if (
    companySettings?.Versioning &&
    publishedVersions?.length > 0 &&
    preferredVersion &&
    preferredVersion !== 'latest' &&
    companyScheduleVersion
  ) {
    let selectedVersion = preferredVersion;
    if (preferredVersion === 'current') {
      selectedVersion = String(companyScheduleVersion.CurrentVersion);
    }
    const selectedPublishedVersion = publishedVersions?.find(
      pv => pv.Version === parseInt(selectedVersion, 10),
    );

    if (
      selectedPublishedVersion?.VisibleStart &&
      moment(selectedPublishedVersion?.VisibleStart).valueOf() > moment(from).valueOf()
    ) {
      baseApiParams.end_time__gt = moment(selectedPublishedVersion?.VisibleStart).format();
    }

    if (
      selectedPublishedVersion?.VisibleEnd &&
      moment(selectedPublishedVersion?.VisibleEnd).valueOf() < moment(to).valueOf()
    ) {
      baseApiParams.start_time__lt = moment(selectedPublishedVersion?.VisibleEnd).format();
    }
  }

  if (
    !userRoles.includes('admin') &&
    !userRoles.includes('owner') &&
    !companySettings.PilotCanViewAllPilotsCrewSchedule &&
    !userRoles.includes('crewScheduleViewer') &&
    !userRoles.includes('taggedScheduleViewer') &&
    !userRoles.includes('subContractor')
  ) {
    const user = yield* selectUserSaga();
    baseApiParams.user_ids = [user.id];
  }

  try {
    const {
      data: { Data },
    } = yield call(api.get, `/v1/companies/${companyID}/timeline/${entryType}`, {
      params: baseApiParams,
    });

    if (entryType === AircraftTimelineEntryType.AircraftSchedule) {
      const entriesArr = (Data || []) as AircraftTimelineEntry[];
      if (isExtension) {
        const existedEntries = yield* selectAircraftScheduleTimelineEntriesSaga();
        const newEntries = _.unionBy(existedEntries, entriesArr, 'VirtualID');

        yield put(
          scheduleActions.setState({
            aircraftScheduleTimelineEntries: newEntries,
          }),
        );
      } else {
        yield put(scheduleActions.setState({ aircraftScheduleTimelineEntries: entriesArr }));
      }
    } else  if (entryType === AircraftTimelineEntryType.MaintenanceAircraftSchedule) {
      const entriesArr = (Data || []) as MaintenanceAircraftTimelineEntry[];
      if (isExtension) {
        const existedEntries = yield* selectMXAircraftScheduleTLEntriesSaga();
        const newEntries = _.unionBy(existedEntries, entriesArr, 'VirtualID');

        yield put(
          scheduleActions.setState({
            mxAircraftScheduleTimelineEntries: newEntries,
          }),
        );
      } else {
        yield put(scheduleActions.setState({ mxAircraftScheduleTimelineEntries: entriesArr }));
      }
    } else {
      const stateKey = entryTypeInStateMap[entryType];

      if (isExtension) {
        const allEntries = yield* selectAllEntriesSagas();
        const existedEntries = allEntries[entryType];
        const newEntries = _.unionBy(existedEntries, Data, 'ID');
        yield put(
          scheduleActions.setState({
            [stateKey]: newEntries,
          }),
        );
      } else {
        if (entryType === CrewTimelineEntryType.CrewSchedule) {
          const issues: { [userID: string]: Issue[] } = {};
          Data.forEach((entry: CrewScheduleTimelineEntry) => {
            if (entry.Issues) {
              issues[entry.UserID] = entry.Issues;
            }
          });
          yield put(scheduleActions.setState({ crewScheduleViolations: issues }));
        }

        yield put(scheduleActions.setState({ [stateKey]: Data }));
      }
    }
  } catch (err) {
    console.log(`Error fetching ${entryType} entries: `, err);
  }
}
