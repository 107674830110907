import { getLaunchDarklyKey } from 'config';
import * as LDClientImpl from 'launchdarkly-js-client-sdk';
import { LDUser } from 'launchdarkly-js-sdk-common';
import React, { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCompany } from 'redux/selectors';

export enum FeatureFlag {
  SettingsPage = 'settings-page',
  BulkScheduleGenerator = 'schedule-generator',
  ReserveOnlyDutyEntry = 'reserve-only-duty-entry',
  BartSyncIntegration = 'bart-sync-integration',
  PilotFlightDataAdjustment = 'pilot-historical-flying-data',
  AircraftPaneScheduling = 'aircraft-pane-scheduling',
  DisplayFDPOnDutyEntries = 'display-fdp-on-duty-entries',
  RemoteSession = 'remote-session',
  CrewScheduleCopyPaste = 'crew-schedule-copy-paste',
  MaintenanceScheduleCopyPaste = 'maintenance-schedule-copy-paste',
  AircraftScheduleCopyPaste = 'aircraft-schedule-copy-paste',
  AutoSaveOnPaste = 'auto-save-on-paste',
  RealTimeCrewSchedule = 'real-time-crew-schedule',
  CollapsibleTimeline = 'collapsible-timeline',
  ResizableTimeline = 'resizable-timeline',
  ReportViewer = 'report-viewer',
  ShowAssociatedCrew = 'show-associated-crew',
  CrewScheduleSandbox = 'crew-schedule-sandbox',
  SandboxUndoAndRedo = 'sanbox-undo-and-redo',
  NewLogsTable = 'new-logs-table',
  AircraftTypeDropdown = 'ame-certification-type-dropdown',
  AircraftDocuments = 'aircraft-documents',
  ComplianceManagementOverride = 'compliance-management-override',
  DetailedFlightTimes = 'detailed-flight-times',
  AllowSupportingDocumentsInRemoteSession = 'allow-supporting-documents-in-remote-session',
  CrewScheduleMobile = 'crew-schedule-mobile',
  UserCanUpdateScheduleEntryTypeFromModal = 'user-can-update-schedule-entry-type-from-modal',
  RemoteAccessVerificationSignatureClosesLog = 'remote-access-verification-signature-closes-log',
  UpdatedFleetOverviewMelFormat = 'updated-fleet-overview-mel-format',
  FlightWatch = 'flight-watch',
  FlightWatchDetails = 'flight-watch-details',
  HideCrewScheduleEntryDetailsForPilots = 'hide-crew-schedule-entry-details-for-pilot',
  HideMaintenanceScheduleEntryDetailsForMaintenance = 'hide-maintenance-schedule-entry-details-for-maintenance',
  PilotDutyReportV2 = 'pilot-duty-report-v-2',
  FlightLandingsReport = 'flight-landings-report',
  EnableSentryReplay = 'enable-sentry-replay',
}

const DEFAULT_FLAG_VALUE = false;

interface FeatureFlagsContext {
  get(featureFlag: FeatureFlag): boolean;
}

const FeatureFlagsContext = React.createContext<FeatureFlagsContext>(undefined);

const FeatureFlagsProvider: React.FC<PropsWithChildren<Object>> = ({ children }) => {
  const company = useSelector(getCompany);

  const initialized = useRef(false);

  const [value, setValue] = useState<FeatureFlagsContext>({ get: () => DEFAULT_FLAG_VALUE });

  const initialize = () => {
    const user: LDUser = { key: company.ID, name: company.Name };
    const client = LDClientImpl.initialize(getLaunchDarklyKey(), user);

    client.waitUntilReady().then(() => {
      setValue({ get: featureFlag => client.variation(featureFlag, DEFAULT_FLAG_VALUE) });
      // client.identify(user, null, () => {
      //   console.log("New user's flags available");
      //   console.log("ComplianceManagementOverride", client.variation(FeatureFlag.ComplianceManagementOverride));
      // });
    });

    return client;
  };

  useEffect(() => {
    if (!company.ID) {
      // The company has not been initialized yet.
      return;
    }

    initialized.current = true;
    const client = initialize();

    return () => {
      client.close();
    };
  }, [company.ID]);

  return <FeatureFlagsContext.Provider value={value}>{children}</FeatureFlagsContext.Provider>;
};

export default FeatureFlagsProvider;

export const useFeatureFlags = () => useContext(FeatureFlagsContext);
