import { PusherActionType, PusherReduxAction } from 'redux/pusher/PusherActions';
import { put, select } from 'redux-saga/effects';
import { getTrainingItemsOverviewState } from '../../tracked-items-overview/selectors';
import { Pilot } from '../../../../common/types/pilot';
import { setState } from '../../tracked-items-overview/actions';

export function* trackedItemUpdated(
  action: PusherReduxAction<PusherActionType.TRACKED_ITEM_UPDATED>,
) {
  const { ID, UserID, DueAtDate } = action.payload;

  const { users } = yield select(getTrainingItemsOverviewState);

  const updatedUsers: Pilot[] = users.map((user: Pilot) => {
    if (user.ID === UserID) {
      const updatedTrackedItems = user.TrackedItems.map(item =>
        item.ID === ID ? { ...item, DueAtDate } : item,
      );

      updatedTrackedItems.sort(
        (a, b) => new Date(a.DueAtDate).getTime() - new Date(b.DueAtDate).getTime(),
      );

      return { ...user, TrackedItems: updatedTrackedItems };
    }
    return user;
  });

  yield put(setState({ users: updatedUsers }));
}
