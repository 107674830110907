import { Action } from 'redux';

export enum PusherActionType {
  CREW_SCHEDULE_ENTRY_CREATED = 'crew-schedule-entry-created',
  CREW_SCHEDULE_ENTRY_UPDATED = 'crew-schedule-entry-updated',
  CREW_SCHEDULE_ENTRY_DELETED = 'crew-schedule-entry-deleted',
  MAINTENANCE_SCHEDULE_ENTRY_CREATED = 'maintenance-schedule-entry-created',
  MAINTENANCE_SCHEDULE_ENTRY_UPDATED = 'maintenance-schedule-entry-updated',
  MAINTENANCE_SCHEDULE_ENTRY_DELETED = 'maintenance-schedule-entry-deleted',
  DUTY_SCHEDULE_ENTRY_CREATED = 'duty-schedule-entry-created',
  DUTY_SCHEDULE_ENTRY_UPDATED = 'duty-schedule-entry-updated',
  DUTY_SCHEDULE_ENTRY_DELETED = 'duty-schedule-entry-deleted',
  DUTY_TIME_ENTRY_CREATED = 'duty-time-entry-created',
  DUTY_TIME_ENTRY_UPDATED = 'duty-time-entry-updated',
  DUTY_TIME_ENTRY_DELETED = 'duty-time-entry-deleted',
  DUTY_TIME_ENTRIES_VALIDATION_STARTED = 'duty-time-entries-validation-started',
  DUTY_TIME_ENTRIES_REVALIDATED = 'duty-time-entries-revalidated',
  DUTY_SCHEDULE_ENTRIES_VALIDATION_STARTED = 'duty-schedule-entries-validation-started',
  DUTY_SCHEDULE_ENTRIES_REVALIDATED = 'duty-schedule-entries-revalidated',
  PILOT_CONTRACT_ENTRY_DELETED = 'pilot-contract-entry-deleted',
  PILOT_CONTRACT_ENTRY_CREATED = 'pilot-contract-entry-created',
  PILOT_CONTRACT_ENTRY_UPDATED = 'pilot-contract-entry-updated',
  AIRCRAFT_CONTRACT_ENTRY_DELETED = 'aircraft-contract-entry-deleted',
  AIRCRAFT_CONTRACT_ENTRY_CREATED = 'aircraft-contract-entry-created',
  AIRCRAFT_CONTRACT_ENTRY_UPDATED = 'aircraft-contract-entry-updated',
  SANDBOX_OS_MERGED = 'sandbox-is-merged',
  CLIENT_CONTRACT_PERIOD_CREATED_UPDATED = 'client-contract-period-created-updated',
  CLIENT_CONTRACT_PERIOD_DELETED = 'client-contract-period-deleted',
  AIRCRAFT_CONTRACTS_BULK_UPDATE = 'aircraft-contracts-bulk-update',
  AIRCRAFT_TABLE_UPDATED = 'aircraft-table-updated',
  SHARED_SANDBOX_UPDATE = 'shared-sandbox-update',
  QUALIFICATION_VALIDATED = 'qualification-validated',
  QUALIFICATIONS_VALIDATED = 'qualifications-validated',
  CERTIFICATE_DELETED = 'certificate_deleted',
  TRACKED_ITEM_UPDATED = 'tracked-item-updated',
  TRACKED_ITEM_CREATED = 'tracked-item-created',
  TRACKED_ITEM_DELETED = 'tracked-item-deleted',
}

type PayloadMapping = {
  [PusherActionType.CREW_SCHEDULE_ENTRY_CREATED]: CrewScheduleEntryUpdateDto;
  [PusherActionType.CREW_SCHEDULE_ENTRY_UPDATED]: CrewScheduleEntryUpdateDto;
  [PusherActionType.CREW_SCHEDULE_ENTRY_DELETED]: CrewScheduleEntryUpdateDto;
  [PusherActionType.MAINTENANCE_SCHEDULE_ENTRY_CREATED]: MaintenanceScheduleEntryUpdateDto;
  [PusherActionType.MAINTENANCE_SCHEDULE_ENTRY_UPDATED]: MaintenanceScheduleEntryUpdateDto;
  [PusherActionType.MAINTENANCE_SCHEDULE_ENTRY_DELETED]: MaintenanceScheduleEntryUpdateDto;
  [PusherActionType.DUTY_SCHEDULE_ENTRY_CREATED]: DutyEntryUpdateDto;
  [PusherActionType.DUTY_SCHEDULE_ENTRY_UPDATED]: DutyEntryUpdateDto;
  [PusherActionType.DUTY_SCHEDULE_ENTRY_DELETED]: DutyEntryUpdateDto;
  [PusherActionType.DUTY_TIME_ENTRY_CREATED]: DutyEntryUpdateDto;
  [PusherActionType.DUTY_TIME_ENTRY_UPDATED]: DutyEntryUpdateDto;
  [PusherActionType.DUTY_TIME_ENTRY_DELETED]: DutyEntryUpdateDto;
  [PusherActionType.DUTY_TIME_ENTRIES_VALIDATION_STARTED]: any;
  [PusherActionType.DUTY_TIME_ENTRIES_REVALIDATED]: any;
  [PusherActionType.DUTY_SCHEDULE_ENTRIES_VALIDATION_STARTED]: any;
  [PusherActionType.DUTY_SCHEDULE_ENTRIES_REVALIDATED]: any;
  [PusherActionType.PILOT_CONTRACT_ENTRY_DELETED]: ContractUpdateDto;
  [PusherActionType.PILOT_CONTRACT_ENTRY_CREATED]: ContractUpdateDto;
  [PusherActionType.PILOT_CONTRACT_ENTRY_UPDATED]: ContractUpdateDto;
  [PusherActionType.AIRCRAFT_CONTRACT_ENTRY_DELETED]: ContractUpdateDto;
  [PusherActionType.AIRCRAFT_CONTRACT_ENTRY_CREATED]: ContractUpdateDto;
  [PusherActionType.AIRCRAFT_CONTRACT_ENTRY_UPDATED]: ContractUpdateDto;
  [PusherActionType.SANDBOX_OS_MERGED]: null;
  [PusherActionType.CLIENT_CONTRACT_PERIOD_CREATED_UPDATED]: ClientContractUpdateDto;
  [PusherActionType.CLIENT_CONTRACT_PERIOD_DELETED]: ClientContractUpdateDto;
  [PusherActionType.AIRCRAFT_CONTRACTS_BULK_UPDATE]: null;
  [PusherActionType.AIRCRAFT_TABLE_UPDATED]: AircraftTableUpdated;
  [PusherActionType.SHARED_SANDBOX_UPDATE]: SharedSandboxUpdate;
  [PusherActionType.QUALIFICATION_VALIDATED]: QualificationValidatedDto;
  [PusherActionType.QUALIFICATIONS_VALIDATED]: QualificationsValidatedDto;
  [PusherActionType.CERTIFICATE_DELETED]: CertificateDeletedDto;
  [PusherActionType.TRACKED_ITEM_CREATED]: TrackedItemDto;
  [PusherActionType.TRACKED_ITEM_UPDATED]: TrackedItemDto;
  [PusherActionType.TRACKED_ITEM_DELETED]: TrackedItemDto;
};

type ContractUpdateDto = {
  ID: string;
  UpdatedBy?: string;
};

type ClientContractUpdateDto = {
  ID: string;
  UpdatedBy?: string;
};

type AircraftTableUpdated = {
  aircraftID: string;
  maintenanceDetails?: string;
};

type SharedSandboxUpdate = {
  sandboxID: string;
  sandboxDetails?: string;
  companyID: string;
};

type TrackedItemDto = {
  ID: string;
  UserID: string;
  DueAtDate: string;
};

type QualificationValidatedDto = {
  QualificationID: string;
  Name: string;
  IsValid: boolean;
  QualificationCertificateIds: string[];
  QualificationCertificateGroupIds: string[];
  UserID: string;
};

type QualificationsValidatedDto = {
  QualificationIDs: string[];
  QualificationsMap: { [key: string]: boolean };
  QualificationsCertificateIds: { [key: string]: string[] };
  QualificationsCertificateGroupIds: { [key: string]: string[] };
  UserID: string;
};

type CertificateDeletedDto = {
  CertificateGroupIDs: string[];
};

type DutyEntryUpdateDto = {
  ID: string;
  UpdatedBy: string;
  CrewScheduleSandboxID?: string;
};

type CrewScheduleEntryUpdateDto = {
  UpdatedBy: string;
  ID: string;
  CrewScheduleSandboxID?: string;
  AssignedAircraft: string[];
};

type MaintenanceScheduleEntryUpdateDto = {
  UpdatedBy: string;
  ID: string;
  CrewScheduleSandboxID?: string;
  AssignedAircraft: string[];
};

export function getPusherPayload<T extends PusherActionType>(
  action: T,
  data: any,
): PayloadMapping[T] {
  switch (action) {
    case PusherActionType.CREW_SCHEDULE_ENTRY_CREATED:
    case PusherActionType.CREW_SCHEDULE_ENTRY_UPDATED:
    case PusherActionType.CREW_SCHEDULE_ENTRY_DELETED:
      return data as CrewScheduleEntryUpdateDto;

    case PusherActionType.MAINTENANCE_SCHEDULE_ENTRY_CREATED:
    case PusherActionType.MAINTENANCE_SCHEDULE_ENTRY_UPDATED:
    case PusherActionType.MAINTENANCE_SCHEDULE_ENTRY_DELETED:
      return data as MaintenanceScheduleEntryUpdateDto;

    case PusherActionType.DUTY_SCHEDULE_ENTRY_CREATED:
    case PusherActionType.DUTY_SCHEDULE_ENTRY_UPDATED:
    case PusherActionType.DUTY_SCHEDULE_ENTRY_DELETED:
    case PusherActionType.DUTY_TIME_ENTRY_CREATED:
    case PusherActionType.DUTY_TIME_ENTRY_UPDATED:
    case PusherActionType.DUTY_TIME_ENTRY_DELETED:
      return data as DutyEntryUpdateDto;

    case PusherActionType.DUTY_TIME_ENTRIES_VALIDATION_STARTED:
    case PusherActionType.DUTY_TIME_ENTRIES_REVALIDATED:
    case PusherActionType.DUTY_SCHEDULE_ENTRIES_VALIDATION_STARTED:
    case PusherActionType.DUTY_SCHEDULE_ENTRIES_REVALIDATED:
      return data; // Assuming `any` type for these actions

    case PusherActionType.PILOT_CONTRACT_ENTRY_DELETED:
    case PusherActionType.PILOT_CONTRACT_ENTRY_CREATED:
    case PusherActionType.PILOT_CONTRACT_ENTRY_UPDATED:
    case PusherActionType.AIRCRAFT_CONTRACT_ENTRY_DELETED:
    case PusherActionType.AIRCRAFT_CONTRACT_ENTRY_CREATED:
    case PusherActionType.AIRCRAFT_CONTRACT_ENTRY_UPDATED:
      return data as ContractUpdateDto;
    case PusherActionType.SANDBOX_OS_MERGED:
    case PusherActionType.AIRCRAFT_CONTRACTS_BULK_UPDATE:
      return null;
    case PusherActionType.AIRCRAFT_TABLE_UPDATED:
    case PusherActionType.SHARED_SANDBOX_UPDATE:
    case PusherActionType.QUALIFICATION_VALIDATED:
    case PusherActionType.QUALIFICATIONS_VALIDATED:
    case PusherActionType.CERTIFICATE_DELETED:
    case PusherActionType.TRACKED_ITEM_UPDATED:
    case PusherActionType.TRACKED_ITEM_CREATED:
    case PusherActionType.TRACKED_ITEM_DELETED:
    default:
      throw new Error(`Unknown action type: ${action}`);
  }
}

export interface PusherReduxAction<T extends PusherActionType> extends Action<T> {
  payload: PayloadMapping[T];
}

export const createPusherReduxAction = <T extends PusherActionType>(
  type: T,
  payload: PayloadMapping[T],
): PusherReduxAction<T> => ({
  type,
  payload,
});
